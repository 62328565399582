/* You can add global styles to this file, and also import other style files */
@import "theme";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "variables";

html,
body {
  height: 100vh;
}
body {
  font-family: Montserrat, "Helvetica Neue", sans-serif;
}

.alert {
  &.alert-danger {
    div.mat-simple-snackbar-action {
      color: $danger;
    }
  }
  &.alert-success {
    div.mat-simple-snackbar-action {
      color: $success;
    }
  }
}

#nav-bar{
  background-image: linear-gradient(to right, #003a77, #20988a);
}

.breadcrumb {
  background-color: var(--accent-color);
  li.breadcrumb-item,
  a {
    color: var(--text-accent-color);
    cursor: pointer;
    + .active {
      color: var(--text-primary-darker-color);
    }
  }
}

.uppercase {
  text-transform: uppercase;
}
.cursor-pointer {
  cursor: pointer;
}
